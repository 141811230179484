import { Content } from "../../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { OutlineButton, PrimaryButton } from "../../../../components/atoms/Button";
import { Divider } from "../../../../components/atoms/Divider";
import { ProgressBar } from "../../../../components/ProgressBar";
import styled from "styled-components";
import { NEUTRAL_300 } from "../../../../constants/colours";
import { useHistory } from "react-router-dom";
import { Form, Text } from "../../../../components/Forms";
import React, { useRef, useState } from "react";
import { useFirebase } from "../../../../hooks/useFirebase";
import SelfAssessmentFields, {
    SelfAssessmentSchema,
    SelfAssessmentValues
} from "../../../../components/SelfAssessmentFields";
import { Collections } from "../../../../constants/collections";
import { UserProfile } from "../../../../types/UserProfile";
import firebase from "firebase/app";
import { useSelfAssessment } from "../../../../hooks/useSelfAssessment";
import { FormikProps } from "formik";
import { Dialog } from "@material-ui/core";
import * as yup from "yup";
import Action from "../../../../components/Action";
import { IconSource } from "../../../../components/atoms/Icon";
import Loader from "../../../../components/Loader";
import { AssessmentCard } from "../../../../components/AssessmentCard";
import getCourseCompletionStatus from "../../../../utils/getCourseCompletionStatus";
import { useEnrolments } from "../../../../hooks/useEnrolments";
import { Enrolment } from "../../../../types/Course";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import { useBCITOCourses, useICONIQCourses, } from "../../../../hooks/useCourses";
import getCourseInformation from "../../../../utils/getCourseInformation";
import { AssessmentNote } from "../../../../components/AssessmentNote";


const StyledDialog = styled(Dialog)`
    .MuiDialog-paper{
        padding: 16px;
        max-width: 80%;
        width:100%;
        
    @media screen and (min-width:768px){
        max-width: 500px;
        }
    }
`;

const CourseCard = styled.div`
    height: 108px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid ${NEUTRAL_300};
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    justify-content: center;
    margin: 8px 0;
    cursor: pointer;
`;

const ButtonGroup = styled.div`
    display: flex;
    
    button:first-of-type {
        margin-right: 8px;
    }
`;

const HeaderRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const MarginButton = styled(PrimaryButton)`
    margin-bottom: 8px;
    margin-top: 32px;
`;

interface StudentOverviewProps {
    profile: UserProfile
}

export default function StudentOverview({ profile }: StudentOverviewProps) {
    const metricsFormRef = useRef<FormikProps<any>>(null);
    const history = useHistory();
    const db = useFirebase().firestore();
    const [todayAssessment, loadingAssessment] = useSelfAssessment(profile.id, new Date());
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [enrolments, loadingEnrolments] = useEnrolments(profile.id);
    const [bcitoCourses, loadingBcitoCourses] = useBCITOCourses();
    const [iconiqCourses, loadingIconiqCourses] = useICONIQCourses();

    const onAbsentPress = () => {
        metricsFormRef.current?.setFieldValue("absent", true);
        setDialogOpen(true);
    };

    const handleMetricSubmit = (values: SelfAssessmentValues) => {
        if (values.tahaHinengaro || values.tahaTinana || values.tahaWairua || values.tahaWhanau) {
            setDialogOpen(true);
        }
    };

    const handleNoteSubmit = async ({ note }: { note: string }) => {
        const metrics = { ...metricsFormRef?.current?.values };
        Object.keys(metrics).forEach(key => metrics[key] === undefined && delete metrics[key]);

        try {
            await db.collection(Collections.assessments).add({
                pid: profile.id,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                ...metrics,
                note
            });
        } catch (e) {
            console.log("error", e);
        } finally {
            setDialogOpen(false);
        }
    };

    const onHistoryPress = () => {
        history.push(`/profiles/students/${profile.id}/history`);
    };

    const onCourseClick = (enrolmentId: string) => {
        history.push(`/enrolments/${enrolmentId}`);
    };

    const renderAssessmentControls = () => {
        if (loadingAssessment) {
            return <Loader small />;
        }

        if (!todayAssessment) {
            return (
                <ButtonGroup>
                    <OutlineButton onClick={onAbsentPress}>Mark absent</OutlineButton>
                    <PrimaryButton type={"submit"} disabled={false}>Submit</PrimaryButton>
                </ButtonGroup>
            );
        }

        if (todayAssessment.note) {
            return <AssessmentNote><p>{todayAssessment?.note}</p></AssessmentNote>;
        }
    };

    if (loadingEnrolments || loadingAssessment || loadingBcitoCourses || loadingIconiqCourses) {
        return <Loader />;
    }

    return (
        <Content>
            <HeaderRow>
                <Col xs>
                    <h3>
                        Daily report
                    </h3>
                </Col>
                <Col>
                    <Action text={"History"} icon={IconSource.next} right onClick={onHistoryPress} />
                </Col>
            </HeaderRow>
            <AssessmentCard>
                <Row>
                    <Col xs>
                        <Form
                            initialValues={{
                                tahaTinana: todayAssessment?.tahaTinana || null,
                                tahaHinengaro: todayAssessment?.tahaHinengaro || null,
                                tahaWairua: todayAssessment?.tahaWairua || null,
                                tahaWhanau: todayAssessment?.tahaWhanau || null,
                                absent: false
                            }}
                            enableReinitialize={true}
                            schema={SelfAssessmentSchema}
                            onSubmit={handleMetricSubmit}
                            innerRef={metricsFormRef}>
                            <SelfAssessmentFields disabled={!!todayAssessment} />
                            {renderAssessmentControls()}
                        </Form>
                    </Col>
                </Row>
            </AssessmentCard>
            <Row>
                <Col xs>
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <h3>
                        Courses
                    </h3>
                </Col>
            </Row>

            {enrolments && enrolments.map((enrolment: Data<Enrolment>) => {
                const completed = getCourseCompletionStatus(enrolment);
                const courses = [...(iconiqCourses || []), ...(bcitoCourses || [])];
                const total = getCourseInformation(courses?.find((c) => c.id === enrolment.courseId)!);
                return (
                    <Row key={`course-card-${enrolment.id}`}>
                        <Col xs>
                            <CourseCard onClick={() => onCourseClick(enrolment.id)}>
                                <span>
                                    {enrolment.course}
                                </span>
                                <ProgressBar total={total} completed={completed} />
                            </CourseCard>
                        </Col>
                    </Row>
                );
            })}
            <StyledDialog open={dialogOpen}>
                <h4>{`Would you like to add a note about Te Whare Tapa Whā?`}</h4>
                <Form initialValues={{ note: "" }} schema={yup.object({ note: yup.string() })}
                      onSubmit={handleNoteSubmit}>
                    <label>ADD NOTE</label>
                    <Text name={"note"} type={""} />
                    <MarginButton type={"submit"}>Save assessment</MarginButton>
                    <OutlineButton onClick={() => setDialogOpen(false)}>Cancel</OutlineButton>

                </Form>
            </StyledDialog>
        </Content>
    );
}
