import styled from "styled-components";
import React from "react";
import { CircularProgress } from "@material-ui/core";
import { NEUTRAL_700, NEUTRAL_900 } from "../constants/colours";

const LoaderContainer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 9999;
    background-color: ${NEUTRAL_700};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    
    .MuiCircularProgress-root {
        color: ${NEUTRAL_900};
    }
`;

type Props = {
    loading: boolean;
}
export default function OverlayLoader(props: Props) {

    if (!props.loading) {
        return null
    }

    return (
        <LoaderContainer>
            <CircularProgress/>
        </LoaderContainer>
    )
}