import React from "react";
import { Content } from "../../../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { useStandard } from "../../../../../hooks/useCourses";
import { Task } from "../../../../../types/Course";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader";
import ListItemLabel from "../../../../../components/ListItemLabel";
import styled from "styled-components";
import { NEUTRAL_200 } from "../../../../../constants/colours";
import Breadcrumb from "../../../../../components/Breadcrumb";
import Checklist from "../../../../../components/Checklist";

const HeadingWrapper = styled.div`
    padding-bottom: 16px;
    border-bottom: 1px solid ${NEUTRAL_200};
    margin-bottom: 16px;
    h3 {
        margin-bottom: 8px;
    }
`;


export default function StandardDetail() {
    const { id, standardId } = useParams<{ id: string, standardId: string }>();
    const [, , standard, loadingStandard] = useStandard(id, Number(standardId));
    const history = useHistory();
    const onTaskClick = (task: Task) => {
        history.push(`/courses/${id}/tasks/${task.id}`);
    };

    const onCourseClick = () => {
        history.goBack();
    };

    if (!standard && loadingStandard) {
        return <Loader />;
    }

    return (
        <Content>
            <Row>
                <Col xs>
                    <Breadcrumb text={"Standards"} onClick={onCourseClick} />
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <HeadingWrapper>
                        <h3>{standard?.name}</h3>
                        <ListItemLabel>{standard?.number}</ListItemLabel>
                    </HeadingWrapper>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    {standard?.tasks.map(task => (
                        <div onClick={() => onTaskClick(task)}>
                            <Checklist text={task.name}
                                       key={task.id}
                                       taskNumber={task.number}
                            />
                        </div>
                    ))}
                </Col>
            </Row>

        </Content>
    );
}
