import { Content } from "../../../../../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../../../../../components/Breadcrumb";
import { useStandard } from "../../../../../../../hooks/useCourses";
import { HeadingD } from "../../../../../../../components/Heading";
import styled from "styled-components";
import Label from "../../../../../../../components/atoms/Label";
import { useEnrolment } from "../../../../../../../hooks/useEnrolments";
import Loader from "../../../../../../../components/Loader";
import { OutlineButton, PrimaryButton } from "../../../../../../../components/atoms/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useState } from "react";
import { Form, Text } from "../../../../../../../components/Forms";
import * as yup from "yup";
import { useAuth } from "../../../../../../../hooks/useAuth";
import { Note } from "../../../../../../../types/Course";
import { NEUTRAL_200, NEUTRAL_300 } from "../../../../../../../constants/colours";

const TaskInfoRow = styled(Row)`
    margin-bottom: 16px;
`;
const CompletedButton = styled(PrimaryButton)`
    margin-top: 64px;
    margin-bottom: 8px;
`;

const StyledDialog = styled(Dialog)`
    .MuiDialog-container{
        padding-top: 36px;
        padding-bottom: 36px
    }
    .MuiDialog-paper{
        padding: 24px;
        width:100%;
        border-radius: 8px;
        max-height: calc(70vh);
        overflow-y: scroll;
    @media screen and (min-width:768px){
        max-width: 500px;
        } 
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;
const CancelButton = styled(OutlineButton)`
    margin-right: 2px;
`;
const AddNoteButton = styled(PrimaryButton)`
    margin-left: 2px;
`;
const Notes = styled(Col)`
    display: flex;
    flex-direction: column;
`;
const NoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    background-color: ${NEUTRAL_200};
    border-color: ${NEUTRAL_300};
    border-width: 1px;
    border-style: solid;
    margin: 4px 0;
`;

export default function EnrolmentTaskDetail() {
    const history = useHistory();
    const { enrolmentId, courseId, taskId } = useParams<{ enrolmentId: string; courseId: string; taskId: string }>();
    const [standardId, taskNumber] = taskId.split(".");
    const [user,] = useAuth();
    const [, , standard, loadingStandard] = useStandard(courseId, Number(standardId));
    const [enrolment, loadingEnrolment] = useEnrolment(enrolmentId);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    if (loadingStandard || loadingEnrolment || !enrolment) {
        return <Loader />;
    }
    const notes = (enrolment.tasks[taskId] || {}).notes || [];
    const completed = (enrolment.tasks[taskId] || {}).completed;
    const onMarkAsCompletedClick = () => {
        const tasks = { ...enrolment.tasks };
        tasks[taskId] = {
            taskId: taskId,
            completed: true,
            notes: notes
        };
        enrolment.ref?.update(`tasks`, tasks);
    };

    const onMarkAsUncomplete = () => {
        const tasks = { ...enrolment.tasks };
        tasks[taskId] = {
            taskId: taskId,
            completed: false,
            notes: notes
        };
        enrolment.ref?.update(`tasks`, tasks);
    };

    const onAddNoteClick = () => {
        setDialogOpen(true);
    };

    const handleNoteSubmit = (values: { note: string }) => {
        const tasks = { ...enrolment.tasks };
        const note = {
            note: values.note,
            createdBy: { uid: user?.uid, name: user?.name },
        };
        tasks[taskId] = {
            taskId: taskId,
            completed: (enrolment.tasks[taskId] || {}).completed || false,
            notes: [...notes, note as Note]
        };
        setDialogOpen(false);

        enrolment.ref?.update(`tasks`, tasks);
    };


    return (
        <Content>
            <>
                <Row>
                    <Col xs>
                        <Breadcrumb onClick={() => history.goBack()} text={standardId} />
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <HeadingD>{standardId} - {taskNumber}</HeadingD>
                    </Col>
                </Row>
                <TaskInfoRow>
                    <Col xs>
                        <span>{standard?.tasks[Number(taskNumber) - 1].name}</span>
                    </Col>
                </TaskInfoRow>
                <Row>
                    <Notes xs>
                        {!!notes.length &&
                        <Label>Assessment notes</Label>
                        }
                        {notes.map((note: Note, index) => {
                            return (
                                <NoteContainer key={`note-${index}`} >
                                    <Label>{`${note.createdBy.name}`}</Label>
                                    <span>
                                    {note.note}
                                    </span>

                                </NoteContainer>
                            );
                        })
                        }
                    </Notes>
                </Row>
            </>
            <Row>
                <Col xs>
                    {completed ? (
                        <CompletedButton onClick={onMarkAsUncomplete}>Mark as uncompleted</CompletedButton>
                    ): (
                        <CompletedButton onClick={onMarkAsCompletedClick}>Mark as completed</CompletedButton>
                    )}
                    <OutlineButton onClick={onAddNoteClick}>Add note</OutlineButton>
                </Col>
            </Row>
            <StyledDialog open={dialogOpen} onBackdropClick={() => setDialogOpen(false)}>
                <Form initialValues={{
                    note: ""
                }} onSubmit={handleNoteSubmit} schema={yup.object({ note: yup.string() })}>
                    <label>ADD NOTE</label>
                    <Text name={"note"} type={""} />
                    <ButtonGroup>
                        <CancelButton onClick={() => setDialogOpen(false)}>Cancel</CancelButton>

                        <AddNoteButton type={"submit"}>Add note</AddNoteButton>
                    </ButtonGroup>
                </Form>
            </StyledDialog>
        </Content>
    );
}
