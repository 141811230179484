import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import React, { Ref, useImperativeHandle, useState } from "react";
import { NEUTRAL_400 } from "../constants/colours";
import { Icon, IconSource } from "./atoms/Icon";
import SelfAssessmentBar from "./SelfAssessmentBar";

const StyledDialog = styled(Dialog)`
    .MuiDialog-container{
        padding-top: 36px;
        padding-bottom: 36px
    }
    .MuiDialog-paper{
        padding: 24px;
        width:100%;
        border-radius: 8px;
        max-height: calc(70vh);
        overflow-y: scroll;
    @media screen and (min-width:768px){
        max-width: 500px;
        }
    }
`;

const NoteWrapper = styled.div`
    padding: 8px 16px;
    background-color: ${NEUTRAL_400};
    border-radius: 8px
`

const DateHeader = styled.h4`
    text-align: center;
`

const AbsentRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export interface DialogRef {
    show: () => void;
    hide: () => void;
}

export interface SelfAssessmentResult {
    formattedDateString: string;
    date: number;
    day: string;
    absent: boolean|undefined;
    tahaTinana?: number;
    tahaWairua?: number;
    tahaWhanau?: number;
    tahaHinengaro?: number;
    note?: string;
}

export interface SelfAssessmentResultProps {
    values: SelfAssessmentResult;
}

function SelfAssessmentResultDialog(props: SelfAssessmentResultProps, ref: Ref<DialogRef>) {
    const [open, setOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide
    }));

    const show = () => {
        setOpen(true);
    };

    const hide = () => {
        setOpen(false);
    };

    return (
        <>
            <StyledDialog onClose={() => null} open={open} onBackdropClick={()=>setOpen(false)}>
                <DateHeader>{props.values.formattedDateString}</DateHeader>
                {props.values.absent?
                    <AbsentRow>
                    <Icon source={IconSource.absent} actionIcon />
                    <span>
                        Marked absent
                    </span>
                    </AbsentRow>:
                    <>
                    <SelfAssessmentBar label={"Taha Tinana (Physical)"} value={props.values.tahaTinana!} disabled />
                    <SelfAssessmentBar label={"Taha Hinengaro (Mental)"} value={props.values.tahaHinengaro!} disabled />
                    <SelfAssessmentBar label={"Taha Wairua (Spiritual)"} value={props.values.tahaWairua!} disabled />
                    <SelfAssessmentBar label={"Taha Whanau (Relationships)"} value={props.values.tahaWhanau!} disabled />
                    </>

                }
                {props.values.note &&
                    <NoteWrapper>
                    <span>{props.values.note}</span>
                    </NoteWrapper>
                }

            </StyledDialog>
        </>
    );
}

export default React.forwardRef(SelfAssessmentResultDialog);
