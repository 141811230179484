import styled from 'styled-components';
import { NEUTRAL_300, NEUTRAL_500, NEUTRAL_900 } from "../../constants/colours";

export const TextArea = styled.textarea`
    color: ${NEUTRAL_900};
    box-sizing: border-box;
    width: 100%;
    border: 1px solid ${NEUTRAL_300};
    border-radius: 8px;
    padding: 12px 16px;
    min-height: 96px;
    
    &:placeholder {
        color: ${NEUTRAL_500};
    }
    &:focus {
        outline: none;
    }
`;


