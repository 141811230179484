import { useFirebase } from "./useFirebase";
import { Collections } from "../constants/collections";
import { UserRole } from "../types/User";
import { UserProfile } from "../types/UserProfile";
import {
    useCollectionData,
    useCollectionDataOnce,
    useDocumentData,
    useDocumentDataOnce
} from "react-firebase-hooks/firestore";


export const useProfiles = () => {
    const firestore = useFirebase().firestore();
    return useCollectionDataOnce<UserProfile>(firestore.collection(Collections.userProfiles), { idField: "id" });
};

export const useMyProfile = (): [profile: UserProfile | null, loading: boolean] => {
    const firebase = useFirebase();

    const uid = firebase.auth().currentUser?.uid || "";
    const [profiles, loading] = useCollectionData<UserProfile>(firebase.firestore().collection(Collections.userProfiles).where("uid", "==", uid), { idField: "id" });

    if (profiles?.length) {
        return [profiles[0], loading];
    }
    return [null, loading];
}

export const useProfile = (id: string) => {
    const firestore = useFirebase().firestore();
    return useDocumentDataOnce<UserProfile>(firestore.collection(Collections.userProfiles).doc(id), { idField: "id" });
};

export const useLiveProfile = (id: string) => {
    const firestore = useFirebase().firestore();
    return useDocumentData<UserProfile>(firestore.collection(Collections.userProfiles).doc(id), { idField: "id" });
};

export const useStaffProfile = (uid: string) => {
    const firestore = useFirebase().firestore();
    return useCollectionData<UserProfile>(firestore.collection(Collections.userProfiles).where("uid", "==", uid), { idField: "id" });
}

export const useStaffProfiles = () => {
    const firestore = useFirebase().firestore();
    const query = firestore.collection(Collections.userProfiles).where("role", "in", [UserRole.tutor, UserRole.manager]);
    return useCollectionDataOnce<UserProfile>(query, { idField: "id" });
};

export const useStudentProfiles = () => {
    const firestore = useFirebase().firestore();
    return useCollectionDataOnce<UserProfile>(firestore.collection(Collections.userProfiles).where("role", "==", UserRole.student), { idField: "id" });
};
