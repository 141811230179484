import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`

  ${normalize}

  body {
    background-color: white;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Questrial', sans-serif;
  }
  
  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 30px;
  }
  
  h3 {
    font-size: 26px;
    line-height: 26px;
  }
  
  h4 {
    margin-top:0px;
    font-size: 20px;
  }
  
  label {
    font-size: 14px;
  }

`;

export { GlobalStyle };
