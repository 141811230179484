import styled from "styled-components";
import Action from "./Action";
import { IconSource } from "./atoms/Icon";

const StyledAction = styled(Action)`
    padding-top: 32px;
`;

type BreadcrumbProps = {
    text: string;
    onClick: () => void;
}

export default function Breadcrumb(props: BreadcrumbProps) {
    const { text, onClick } = props;

    return (
        <StyledAction text={text} icon={IconSource.back} onClick={onClick} />
    );
}
