import styled from "styled-components";
import { NEUTRAL_300, NEUTRAL_500, NEUTRAL_900 } from "../../constants/colours";

export const SelectElement = styled.select`
    color: ${NEUTRAL_900};
    box-sizing: border-box;
    border: 1px solid ${NEUTRAL_300};
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    
    &:placeholder {
        color: ${NEUTRAL_500};
    }
    
    &:focus {
        outline: none;
    }
`;

export const OptionElement = styled.option`
`;