import styled from "styled-components";
import React from "react";
import New from "../../resources/icons/Atom - Icon - Plus@2x.png";
import Save from "../../resources/icons/Atom - Icon - Tick@2x.png";
import Cancel from "../../resources/icons/Atom - Icon - Cross@2x.png";
import Back from "../../resources/icons/Atom - Icon - Cheveron - Left@2x.png";
import Next from "../../resources/icons/Atom - Icon - Cheveron - Right@2x.png";
import Search from "../../resources/icons/Atom - Icon - Search@2x.png";
import Absent from "../../resources/icons/absentArrow@2x.png"

const Image = styled.img<IconProps>`
    height: ${props => props.actionIcon ? "12px" : "20px"};
    width: ${props => props.actionIcon ? "12px" : "20px"};
    padding: ${props => props.actionIcon ? "0" : "4px"};
    text-align: center;
`;

export enum IconSource {
    new = "new",
    save = "save",
    cancel = "cancel",
    back = "back",
    next = "next",
    search = "search",
    absent= "absent"
}

const icons = {
    new: New,
    save: Save,
    cancel: Cancel,
    back: Back,
    next: Next,
    search: Search,
    absent: Absent
};

interface IconProps {
    source: IconSource;
    actionIcon?: boolean;
}

export const Icon = (props: IconProps) => {
    return (
        <Image src={icons[props.source]} {...props} />
    )
}
