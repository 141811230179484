import React from "react";
import styled from "styled-components";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { NEUTRAL_900 } from "../constants/colours";

interface LoaderProps {
    small?: boolean;
}
const LoaderContainer = styled.div<LoaderProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.small ? "" : "calc(100vh - 50px)"};
`;

const loaderStyles = makeStyles(() => ({
    root: {
        color: NEUTRAL_900
    }
}));

export default function Loader(props: LoaderProps) {
    const classes = loaderStyles();

    return (
        <LoaderContainer {...props}>
            <CircularProgress className={classes.root} />
        </LoaderContainer>
    );
}