import Dialog from "@material-ui/core/Dialog";
import React, { Ref, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../hooks/useFirebase";
import { OutlineButton, RedButton } from "./atoms/Button";
import { Collections } from "../constants/collections";
import { UserProfile } from "../types/UserProfile"


const StyledDialog = styled(Dialog)`
    .MuiDialog-container{
        padding-top: 36px;
        padding-bottom: 36px;
    }
    
    .MuiDialog-paper{
        padding: 16px;
        max-height: calc(70vh);
        max-width: 80%;
        width:100%;
        
    @media screen and (min-width:768px){
        max-width: 500px;
        }
    }
`;

const ButtonGroup = styled.div`
    margin-top:12px;
    display: flex;
    
`;

interface DialogProps {
    submitted?: () => void;
    profile: UserProfile
}

export interface DialogRef {
    show: () => void;
    hide: () => void;
}

function DeleteUserDialog(props: DialogProps, ref: Ref<DialogRef>) {
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();
    const { profile } = props
    const firestore = useFirebase().firestore()

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide
    }))

    const show = () => {
        setOpen(true);
    }

    const hide = () => {
        setOpen(false);
    }
    const onDeleteClick = async (values: any) => {
        await firestore.collection(Collections.userProfiles).doc(profile!.id).delete().then(() => {
            // console.log('Deleted user', profile!.id)
        })
        history.push(`/profiles/students`);
    };
    return (
        <>
            <StyledDialog onClose={() => null} open={open}>
                <p>Are you sure you want to delete this user?</p>
                <p>Doing so will delete their profile card and remove all related information. This cannot be
                    un-done</p>
                <ButtonGroup>
                    <OutlineButton onClick={() => setOpen(false)}>Cancel</OutlineButton>
                    <RedButton onClick={onDeleteClick}>Delete</RedButton>
                </ButtonGroup>
            </StyledDialog>
        </>
    );


}

export default React.forwardRef(DeleteUserDialog);
