import React, { useContext, useRef } from "react";
import { Content } from "../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { HeadingL } from "../../../components/Heading";
import { Error, FieldGroup, Form, Input, Label, Select } from "../../../components/Forms";
import * as yup from 'yup';
import { PrimaryButton } from "../../../components/atoms/Button";
import { UserRole } from "../../../types/User";
import { useFirebase } from "../../../hooks/useFirebase";
import { FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { LoadingContext } from "../../../context/LoadingContext";
import Routes from "../../../constants/routes";
import Breadcrumb from "../../../components/Breadcrumb";

const StaffSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Email not valid").required("Required"),
    phone: yup.string().required("Required")
})

export default function StaffInvite() {
    const formRef = useRef<FormikProps<any>>(null);
    const history = useHistory();
    const firebase = useFirebase();
    const auth = firebase.auth();
    const [inviting, setInviting] = useContext(LoadingContext);

    const submit = async ({ email, firstName, lastName, phone, role }: any) => {
        setInviting(true);
        const encode = firebase.functions().httpsCallable("encodeRole");
        const result = await encode({
            role,
            email
        });
        const encodedRole = result.data;

        const url = `${process.env.REACT_APP_BASE_URL}/login?firstName=${firstName}&lastName=${lastName}&phone=${phone}&user=${encodedRole}`;
        await auth.sendSignInLinkToEmail(email, { url: url, handleCodeInApp: true })
            .then(() => {
                history.push(Routes.staff.list);
            })
            .catch((e: any) => console.error("firebase sign in error", e.code));

        setInviting(false);
    };

    const roleOptions = [
        { label: "Administrator", value: UserRole.administrator },
        { label: "Manager", value: UserRole.manager },
        { label: "Tutor", value: UserRole.tutor },
    ]

    return (
        <Content>
            <Row>
                <Col xs>
                    <Breadcrumb text={"iconiq staff"} onClick={() => history.goBack()}/>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <HeadingL>Invite Staff</HeadingL>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <Form initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        role: UserRole.tutor
                    }} schema={StaffSchema} onSubmit={submit} innerRef={formRef}>
                        <FieldGroup>
                            <Label forName={"firstName"}>first name</Label>
                            <Input name={"firstName"} type={"text"}/>
                            <Error forName={"firstName"}/>
                        </FieldGroup>
                        <FieldGroup>
                            <Label forName={"lastName"}>last name</Label>
                            <Input name={"lastName"} type={"text"}/>
                            <Error forName={"lastName"}/>
                        </FieldGroup>
                        <FieldGroup>
                            <Label forName={"email"}>email address</Label>
                            <Input name={"email"} type={"email"}/>
                            <Error forName={"email"}/>
                        </FieldGroup>
                        <FieldGroup>
                            <Label forName={"phone"}>contact number</Label>
                            <Input name={"phone"} type={"tel"}/>
                            <Error forName={"phone"}/>
                        </FieldGroup>
                        <FieldGroup>
                            <Label>role</Label>
                            <Select name={"role"} options={roleOptions}/>
                            <Error forName={"role"}/>
                        </FieldGroup>
                        <PrimaryButton type={"submit"} disabled={inviting}>Save & Continue</PrimaryButton>
                    </Form>
                </Col>
            </Row>
        </Content>
    )
}
