import React, { useEffect, useState } from "react";
import { Content } from "../../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { HeadingD } from "../../../../components/Heading";
import { useHistory, useParams } from "react-router-dom";
import { useCourse } from "../../../../hooks/useCourses";
import Loader from "../../../../components/Loader";
import * as qs from "qs";
import { Module, Standard } from "../../../../types/Course";
import SearchInput from "../../../../components/SearchInput";
import CourseToggleSelect from "../../../../components/CourseToggleSelect";
import ListItemLabel from "../../../../components/ListItemLabel";
import { IconSource } from "../../../../components/atoms/Icon";
import styled from "styled-components";
import Breadcrumb from "../../../../components/Breadcrumb";

const SearchInputWrapper = styled.div`
    padding: 0px 0 16px;
`;

const useModule = (courseId: string, module: any): [Module | undefined, boolean] => {
    const [course, loading] = useCourse(courseId);
    let m: Module | undefined = undefined;

    if (!loading) {
        const filteredModules: Module[] = (course?.modules || []).filter(m => m.name === module);
        if (filteredModules.length) {
            m = filteredModules[0];
        }
    }

    return [m, loading];
};

export default function ModuleDetail() {
    const { id } = useParams<{ id: string }>();
    const { q } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const [search, setSearch] = useState<string>("");
    const history = useHistory();

    useEffect(() => {
        if (!id || !q) {
            history.replace("/courses");
        }
    }, [id, history, q]);

    const [module, loadingModule] = useModule(id, q);

    const onStandardClick = (standard: Standard) => {
        history.push(`/courses/${id}/standards/${standard.number}`);
    };

    const onModulesClick = () => {
        history.replace(`/courses/${id}`);
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value.toLowerCase());
    };

    if (loadingModule) {
        return <Loader />;
    }

    return (
        <Content>
            <Row>
                <Col xs>
                    <Breadcrumb text={"Modules"} onClick={onModulesClick} />
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <HeadingD>{module?.name}</HeadingD>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <SearchInputWrapper>
                        <SearchInput placeholder={"Search"} onChange={onSearchChange} />
                    </SearchInputWrapper>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <ListItemLabel>Standards</ListItemLabel>
                    {module?.standards.filter(s => s.name.toLowerCase().includes(search)).map((standard: Standard) => (
                        <CourseToggleSelect key={standard.number}
                                            course={standard.name}
                                            icon={IconSource.next}
                                            number={standard.number}
                                            onClick={() => onStandardClick(standard)} />
                    ))}
                </Col>
            </Row>
        </Content>
    );
}
