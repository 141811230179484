import { useCollectionData, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useFirebase } from "./useFirebase";
import { Collections } from "../constants/collections";
import { SelfAssessment } from "../types/SelfAssessment";
import { startOfDay, endOfDay } from "date-fns";

import firebase from "firebase/app";

export function useSelfAssessment(userId: string, date: Date): [SelfAssessment | null, boolean] {
    const firestore = useFirebase().firestore();
    const startDay = startOfDay(date);
    const endDay = endOfDay(date);

    const query = firestore.collection(Collections.assessments)
        .where("pid", "==", userId || "")
        .where("date", ">=", firebase.firestore.Timestamp.fromDate(startDay))
        .where("date", "<=", firebase.firestore.Timestamp.fromDate(endDay))
    const [data, loading] = useCollectionData<SelfAssessment>(query);

    if (data) {
        return [data[0], loading]
    }
    return [null, loading];
}

export function useSelfAssessmentsRange(userId: string, startDate: Date, endDate: Date):[SelfAssessment[] | null, boolean] {
    const firestore = useFirebase().firestore();
    const startDay = startOfDay(startDate);
    const endDay = endOfDay(endDate);

    const query = firestore.collection(Collections.assessments)
        .where("pid", "==", userId)
        .where("date", ">=", firebase.firestore.Timestamp.fromDate(startDay))
        .where("date", "<=", firebase.firestore.Timestamp.fromDate(endDay))
    const [data, loading] = useCollectionDataOnce<SelfAssessment>(query);
    if (data) {
        return [data, loading]
    }
    return [null, loading];
}
