import React, { InputHTMLAttributes } from "react";
import { TextInput } from "./atoms/TextInput";
import styled from "styled-components";
import { NEUTRAL_200, NEUTRAL_500, NEUTRAL_900 } from "../constants/colours";
import { Icon, IconSource } from "./atoms/Icon";

const InputWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-color: ${NEUTRAL_200};
    border-radius: 12px;
    margin: 16px 0;
    
    input {
        border: none;
        padding-right: 16px;
        &:placeholder {
            color: ${NEUTRAL_500};
        }
        &:focus {
            color: ${NEUTRAL_900};
        }
    }
    
    img {
        padding-right: 8px;
        cursor: pointer;
    }
`;

export default function SearchInput(props: InputHTMLAttributes<HTMLInputElement>) {
    return (
        <InputWrapper>
            <TextInput {...props} />
            <Icon source={IconSource.search} />
        </InputWrapper>
    );
}