import React, { useEffect, useRef, useState } from "react";
import { Content } from "../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { HeadingXL } from "../../../components/Heading";
import { useAuth } from "../../../hooks/useAuth";
import { Divider } from "../../../components/atoms/Divider";
import { OutlineButton } from "../../../components/atoms/Button";
import SelfAssessmentDialog, { DialogRef } from "../../../components/SelfAssessmentDialog";
import { DAILY_ASSESSMENT_COMPLETED, DAILY_ASSESSMENT_VIEWED } from "../../../constants/constants";
import { useHistory } from "react-router-dom";
import { UserProfile } from "../../../types/UserProfile";
import { useStudentProfiles } from "../../../hooks/useProfiles";
import Action from "../../../components/Action";
import { IconSource } from "../../../components/atoms/Icon";
import Loader from "../../../components/Loader";
import styled from "styled-components";

const compareAssessmentDate = (localStorageKey: string): boolean => {
    const dateString = window.localStorage.getItem(localStorageKey);
    if (dateString) {
        const viewedDate = new Date(dateString);
        const currentDate = new Date();
        const viewedString = `${viewedDate.getDate()}/${viewedDate.getMonth()}/${viewedDate.getFullYear()}`;
        const currentString = `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`;

        if (viewedString === currentString) {
            return true;
        }
    }

    return false;
}

const ActionPadded = styled(Action)`
padding-top:32px;
`
const StudentName = styled.span`
cursor:pointer;
`

export default function Students() {
    const history = useHistory();
    const [students, loading] = useStudentProfiles()
    const [user] = useAuth();
    const [assessComplete, setAssessComplete] = useState<boolean>(compareAssessmentDate(DAILY_ASSESSMENT_COMPLETED));
    const dialogRef = useRef<DialogRef>(null);
    const onStudentClick = (student: UserProfile) => {
        history.push(`/profiles/students/${student.id}`);
    };

    const onAddPress = () => {
        history.push(`/profiles/students/add`)
    };

    useEffect(() => {
        if (!compareAssessmentDate(DAILY_ASSESSMENT_VIEWED)) {
            dialogRef.current?.show();
            window.localStorage.setItem(DAILY_ASSESSMENT_VIEWED, new Date().toISOString())
        }
    }, [])


    return (
        <Content>
            {loading ?
               <Loader/>
                : <>
                    <Row>
                        <Col xs>
                            <HeadingXL>{`Kia ora ${user?.name ?? ""}`}</HeadingXL>
                        </Col>
                    </Row>
                    {!assessComplete && (
                        <Row>
                            <OutlineButton onClick={() => dialogRef.current?.show()}>Assess your Te Whare Tapa Whā</OutlineButton>
                        </Row>
                    )}
                    <Row>
                        <Col xs>
                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <h3>Find a student</h3>
                        </Col>
                        <Col>
                            <ActionPadded text={"new"} icon={IconSource.new} right onClick={onAddPress}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <Divider />
                        </Col>
                    </Row>
                    {students?.map((student, index) => (
                        <Row onClick={()=>onStudentClick(student)} key={`student-${index}`}>
                            <Col xs>
                                <StudentName>{student?.firstName} {student?.lastName}</StudentName>
                                <Divider />
                            </Col>
                        </Row>
                    ))}
                </>
            }
            <SelfAssessmentDialog ref={dialogRef} onAssessmentCompleted={() => setAssessComplete(true)}/>

        </Content>
    );
}
