import React, { createContext, useContext } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export type FirebaseUser = firebase.User;
export const FirebaseContext = createContext<any>(null);

export const FirebaseProvider: React.FC = ({ children }) => {
  if (!firebase.apps.length) {
    const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);
    firebase.initializeApp(config);

    if (window.location.hostname === 'localhost') {
      firebase.auth().useEmulator('http://localhost:9099');
      firebase.firestore().useEmulator('localhost', 8080);
      firebase.functions().useEmulator('localhost', 5001);
    }
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

// from context provided firebase
const useFirebase = (): firebase.app.App => {
  return useContext(FirebaseContext);
};

const EmailAuthProvider = firebase.auth.EmailAuthProvider;

export { useFirebase, EmailAuthProvider };
