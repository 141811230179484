import { Course, Module, Standard, Task } from "../types/Course";


export default function getCourseInformation(course: Course){
    let totalCount = 0;

    course.modules.forEach((module: Module) => {
        module.standards.forEach((standard: Standard) => {
            standard.tasks.forEach((task: Task) => {
                totalCount+=1
            });
        });
    });
    return totalCount
}
