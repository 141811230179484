import useCollectionRef from "./useCollectionRef";
import { Collections } from "../constants/collections";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { Enrolment } from "../types/Course";

export function useEnrolments(studentId: string) {
    const query = useCollectionRef(Collections.enrolments).where("studentId", "==", studentId);
    return useCollectionData<Enrolment>(query, { idField: "id" });
}

export function useEnrolment(enrolmentId: string) {
    const query = useCollectionRef(Collections.enrolments).doc(enrolmentId);
    return useDocumentData<Enrolment>(query, { idField: "id", refField: "ref" });
}
