import styled from "styled-components";
import { NEUTRAL_100, NEUTRAL_200, NEUTRAL_500, YELLOW_500 } from "../constants/colours";
import { Icon, IconSource } from "./atoms/Icon";

const CourseContainer = styled.div<CourseToggleSelectProps>`
    width: 100%;
    background-color: ${props => props.selected ? YELLOW_500 : NEUTRAL_200};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 12px;
`;

const InnerText = styled.span`
    padding: 12px 16px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px
`;

const NumberSpan = styled.span<{ selected?: boolean }>`
    color: ${props => props.selected?NEUTRAL_100:NEUTRAL_500};
    margin-right: 8px;
`;

type CourseToggleSelectProps = {
    course: string;
    selected?: boolean;
    icon?: IconSource;
    onClick: () => void;
    number?: number;
}

export default function CourseToggleSelect(props: CourseToggleSelectProps) {
    const { onClick, course, icon, selected, number } = props;

    return (
        <CourseContainer selected={selected} {...props} onClick={onClick}>
            <InnerText>
                {course}
            </InnerText>
            {icon && (
                <IconWrapper>
                    {number && <NumberSpan selected={selected}>{number}</NumberSpan>}
                    <Icon source={icon} />
                </IconWrapper>
            )}
        </CourseContainer>
    );
}
