import styled from "styled-components";
import { YELLOW_500 } from "../constants/colours";

type ProgressBarProps = {
    total: number;
    completed: number;
}

const InnerDiv = styled.div<ProgressBarProps>`
    background-color: ${YELLOW_500};
    width: ${props => (props.completed / props.total) * 100}%;
    height: 16px;
    `;
const OuterDiv = styled.div`
    background-color: #F4F4F5;
    width: 100%;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
`;
const BarWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const BarDetails = styled.div`
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
`;
const BarDetail = styled.span`
    font-size: 14px;
    color: #999999;
`;

export const ProgressBar = (props: ProgressBarProps) => (
    <BarWrapper>
        <BarDetails>
            <BarDetail>
                {props.completed === props.total ? "Completed" : `${props.completed}/${props.total} tasks completed`}
            </BarDetail>
            <BarDetail>
                {props.completed === props.total ? "100%" : `${((props.completed / props.total) * 100).toFixed(1)}%`}
            </BarDetail>
        </BarDetails>
        <OuterDiv>
            <InnerDiv total={props.total} completed={props.completed} />
        </OuterDiv>
    </BarWrapper>
);
