import React, { useRef, useState } from "react";
import { Content } from "../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { useMyProfile } from "../../hooks/useProfiles";
import { AssessmentCard } from "../../components/AssessmentCard";
import SelfAssessmentFields, {
    SelfAssessmentSchema,
    SelfAssessmentValues
} from "../../components/SelfAssessmentFields";
import { Error, FieldGroup, Form, Input, Label, Text } from "../../components/Forms";
import { FormikProps } from "formik";
import { useSelfAssessment } from "../../hooks/useSelfAssessment";
import * as yup from "yup";
import { OutlineButton, PrimaryButton } from "../../components/atoms/Button";
import { Collections } from "../../constants/collections";
import firebase from "firebase/app";
import { useFirebase } from "../../hooks/useFirebase";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb";
import Routes from "../../constants/routes";
import { useHistory } from "react-router-dom";
import { HeadingD, HeadingL } from "../../components/Heading";
import Action from "../../components/Action";
import { IconSource } from "../../components/atoms/Icon";
import LabeledValueRow from "../../components/LabeledValueRow";
import useCollectionRef from "../../hooks/useCollectionRef";
import Loader from "../../components/Loader";
import { AssessmentNote } from "../../components/AssessmentNote";
import { DAILY_ASSESSMENT_COMPLETED } from "../../constants/constants";

const EditSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email(),
    phone: yup.string(),
});

type ProfileValues = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

const HeaderRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledDialog = styled(Dialog)`
    .MuiDialog-paper{
        padding: 16px;
        max-width: 80%;
        width:100%;
        
    @media screen and (min-width:768px){
        max-width: 500px;
        }
    }
`;
const MarginButton = styled(PrimaryButton)`
    margin-bottom: 8px;
    margin-top: 32px;
`;

const SaveButton = styled(PrimaryButton)`
    margin: 8px 0;
`

export default function MyProfile() {
    const [profile, loadingProfile] = useMyProfile();
    const metricsFormRef = useRef<FormikProps<any>>(null);
    const [todayAssessment, loadingAssessment] = useSelfAssessment(profile ? profile.id : "", new Date());
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const firestore = useFirebase().firestore();
    const history = useHistory();
    const profileCollection = useCollectionRef(Collections.userProfiles);

    const handleMetricSubmit = (values: SelfAssessmentValues) => {
        if (values.tahaHinengaro || values.tahaTinana || values.tahaWairua || values.tahaWhanau) {
            setDialogOpen(true);
        }
    };

    const handleNoteSubmit = async ({ note }: { note: string }) => {
        const metrics = { ...metricsFormRef?.current?.values };
        Object.keys(metrics).forEach(key => metrics[key] === undefined && delete metrics[key]);

        try {
            await firestore.collection(Collections.assessments).add({
                pid: profile?.id,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                ...metrics,
                note
            });
            window.localStorage.setItem(DAILY_ASSESSMENT_COMPLETED, new Date().toISOString());
        } catch (e) {
            console.log("error", e);
        } finally {
            setDialogOpen(false);
        }
    };

    const profileSubmit = async (values: ProfileValues) => {
        try {
            if (profile?.id) {
                await profileCollection.doc(profile.id).update(values).then(() => {
                    setEditing(false);
                })
            }
        } catch (e) {
            console.log('err', e);
        }
    }

    const renderAssessmentControls = () => {
        if (loadingAssessment) {
            return <Loader small/>;
        }

        if (!todayAssessment) {
            return (
                <PrimaryButton type={"submit"} disabled={false}>Submit</PrimaryButton>
            );
        }

        if (todayAssessment.note) {
            return <AssessmentNote><p>{todayAssessment?.note}</p></AssessmentNote>;
        }
    };

    return (
        <Content>
            <Row>
                <Col xs>
                    <Breadcrumb text={"iconiq staff"} onClick={() => history.push(Routes.staff.list)}/>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    {!loadingProfile && <HeadingL>{`${profile?.firstName} ${profile?.lastName}`}</HeadingL>}
                </Col>
            </Row>
            <HeaderRow>
                <Col xs>
                    <HeadingD>Daily report</HeadingD>
                </Col>
                <Col>
                    <Action text={"history"} icon={IconSource.next} right
                            onClick={() => history.push(`${Routes.staff.list}/${profile?.id}/history`)}/>
                </Col>
            </HeaderRow>
            <AssessmentCard>
                <Row>
                    <Col xs>
                        <Form initialValues={{
                            tahaTinana: todayAssessment?.tahaTinana || null,
                            tahaHinengaro: todayAssessment?.tahaHinengaro || null,
                            tahaWairua: todayAssessment?.tahaWairua || null,
                            tahaWhanau: todayAssessment?.tahaWhanau || null,
                        }}
                              enableReinitialize={true}
                              schema={SelfAssessmentSchema}
                              onSubmit={handleMetricSubmit}
                              innerRef={metricsFormRef}>
                            <SelfAssessmentFields disabled={!!todayAssessment}/>
                            {renderAssessmentControls()}
                        </Form>
                    </Col>
                </Row>
            </AssessmentCard>

            <Row>
                <Col xs>
                    <HeadingD>Details</HeadingD>
                    {editing
                        ? (<Form initialValues={{
                            firstName: profile?.firstName,
                            lastName: profile?.lastName,
                            email: profile?.email,
                            phone: profile?.phone
                        }} schema={EditSchema} onSubmit={profileSubmit} enableReinitialize={true}>
                            <FieldGroup>
                                <Label forName={"firstName"}>first name</Label>
                                <Input name={"firstName"} type={"text"}/>
                                <Error forName={"firstName"}/>
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"lastName"}>last name</Label>
                                <Input name={"lastName"} type={"text"}/>
                                <Error forName={"lastName"}/>
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"email"}>email address</Label>
                                <Input name={"email"} type={"email"}/>
                                <Error forName={"email"}/>
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"phone"}>contact number</Label>
                                <Input name={"phone"} type={"tel"}/>
                                <Error forName={"phone"}/>
                            </FieldGroup>
                            <SaveButton type={"submit"}>Save</SaveButton>
                            <OutlineButton onClick={() => setEditing(false)}>Cancel</OutlineButton>
                        </Form>)
                        : (<>
                            <LabeledValueRow label={"full name"} value={`${profile?.firstName} ${profile?.lastName}`}/>
                            <LabeledValueRow label={"email address"} value={profile?.email}/>
                            <LabeledValueRow label={"contact number"} value={profile?.phone}/>
                            <LabeledValueRow label={"role"} value={profile?.role}/>
                            {<OutlineButton onClick={() => setEditing(true)}>Edit details</OutlineButton>}
                        </>)}
                </Col>
            </Row>

            <StyledDialog open={dialogOpen}>
                <h4>{`Would you like to add a note about Te Whare Tapa Whā?`}</h4>
                <Form initialValues={{ note: "" }} schema={yup.object({ note: yup.string() })}
                      onSubmit={handleNoteSubmit}>
                    <label>ADD NOTE</label>
                    <Text name={"note"} type={""}/>
                    <MarginButton type={"submit"}>Save assessment</MarginButton>
                    <OutlineButton onClick={() => setDialogOpen(false)}>Cancel</OutlineButton>
                </Form>
            </StyledDialog>
        </Content>
    )
}
