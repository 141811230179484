import {
    Field,
    FieldProps,
    ErrorMessage,
    Formik,
    FormikHelpers,
    FieldArray as FormikFieldArray,
    Form as FormikForm,
    useFormikContext,
} from "formik";
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TextInput } from "./atoms/TextInput";
import { TextArea } from "./atoms/TextArea";
import { NEUTRAL_500 } from "../constants/colours";
import { LABEL } from "../constants/typography";
import SelfAssessmentBar from "./SelfAssessmentBar";
import { OptionElement, SelectElement } from "./atoms/Select";

export interface FormHelpers<Values> extends FormikHelpers<Values> {
}

export const FieldArray = FormikFieldArray;

interface FormProps<Values> {
    initialValues: Values;
    schema: any;
    onSubmit: (
        values: Values,
        helpers: FormHelpers<Values>
    ) => void | Promise<any>;
    children: ReactNode;
    innerRef?: React.Ref<any>;
    enableReinitialize?: boolean;
}

interface InputProps {
    name: string;
    type: string;
    placeholder?: string;
}

interface SelectProps {
    name: string;
    options: { label: string, value: any }[]
}

interface CheckboxProps {
    name: string;
}

interface SelfAssessmentProps {
    name: string;
    label: string;
    disabled?: boolean;
}

interface LabelProps {
    forName?: string;
    children: ReactNode;
}

interface ErrorProps {
    forName?: string;
}

const FieldGroupElement = styled.div`
  margin-bottom: 1rem;
`;

const CheckboxElement = styled.input`
  box-sizing: border-box;
  padding: 0.4rem;
  border: 1px solid black;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
`;

const LabelElement = styled.label<any>`
  ${LABEL};
  color: ${NEUTRAL_500};
  text-transform: uppercase;
  display: block;
  font-size:14px;
  margin-bottom: 4px;
`;

const ErrorElement = styled.div`
  font-size: 80%;
  color: red;
  margin: 0.4rem 0;
`;

export const FieldGroup: React.FC = ({ children }) => {
    return <FieldGroupElement>{children}</FieldGroupElement>;
};

export const Label = ({ forName, children }: LabelProps) => {
    return <LabelElement htmlFor={forName}>{children}</LabelElement>;
};

export const Input = (props: InputProps) => {
    return (
        <Field name={props.name}>
            {({ field, meta }: FieldProps) => <TextInput {...props} {...field} />}
        </Field>
    );
};

export const Text = (props: InputProps) => {
    return (
        <Field name={props.name}>
            {({ field, meta }: FieldProps) => (
                <TextArea {...props} {...field} />
            )}
        </Field>
    );
};

export const Select = (props: SelectProps) => {
    return (
        <Field name={props.name}>
            {({ field, meta }: FieldProps) => (
                <SelectElement {...field}>{props.options.map(option => (
                    <OptionElement key={option.value} value={option.value}>{option.label}</OptionElement>))}</SelectElement>
            )}
        </Field>
    );
};

export const Checkbox = (props: CheckboxProps) => {
    return (
        <Field name={props.name}>
            {({ field, meta }: FieldProps) => (
                <CheckboxElement type="checkbox" {...field} checked={field.value}/>
            )}
        </Field>
    );
};

export const SelfAssessment = (props: SelfAssessmentProps) => {
    return (
        <Field name={props.name}>
            {({ field, form, meta }: FieldProps) => (
                <SelfAssessmentBar label={props.label} disabled={props.disabled} {...field} {...form} {...meta} />
            )}
        </Field>
    )
}


export const Error = ({ forName }: ErrorProps) => {
    const { status } = useFormikContext();

    if (!!forName) {
        return (
            <ErrorElement>
                <ErrorMessage name={forName}/>
            </ErrorElement>
        );
    }

    if (status && status.error) {
        return <ErrorElement>{status.error.message}</ErrorElement>;
    }

    return null;
};

export const Form = (props: FormProps<any>) => {
    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={props.schema}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={props.innerRef}
            onSubmit={props.onSubmit}
            enableReinitialize={props.enableReinitialize}
        >
            <FormikForm noValidate={true}>{props.children}</FormikForm>
        </Formik>
    );
};
