import styled from "styled-components";
import LineTo from "react-lineto";
import { NEUTRAL_300, YELLOW_500 } from "../constants/colours";

const TableData = styled.div<{index: number}>`
    display: table-cell;
    border-top:1px dashed ${YELLOW_500};
    border-bottom:0px solid  ${YELLOW_500};
    border-left: 1px solid ${NEUTRAL_300};
    border-right 1px solid ${NEUTRAL_300};
    position: relative;
    height: 16px;
`;


const TableDot = styled.div`
    height: 13px;
    width: 13px;
    align-self: center;
    border-width: 1px;
    border-color: ${NEUTRAL_300};
    border-style: solid;
    border-radius: 100%;
    background-color:  ${YELLOW_500};  
`;
const Table = styled.div`
    border-collapse: collapse;
    width: 100%;
    display: table;
`;

const TableRow = styled.div`
    display: table-row;
`;
const TableDotWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: -9px;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    
`;

type LineGraphProps = {
    data: number[];
    category: string;

    onCellPress: (scoreIndex:number) =>void;
}

export default function LineGraph(props: LineGraphProps) {
    const { data } = props;
    const scores = data;
    const category = props.category;



    return (
        <Table>

            <RenderTableRows scores={scores} category={category} onCellPress={props.onCellPress}/>
            <RenderTableLines category={category} scores={scores} />
        </Table>
    );
}

interface TableLinesProps {
    category: string;
    scores: number[];
}

interface TableRowsProps {
    scores: number[];
    category: string;
    onCellPress: (scoreIndex:number)=>void
}

const RenderTableRows = (props: TableRowsProps) => {
    const scores = props.scores
    const category=props.category
    const valueArray = [];
    const max = Math.max(...scores);
    for (let i = max - 1; i >= 0; i--) {
        valueArray.push(i);
    }
    return (
        <>
            <RenderTableRow index={4} scores={scores} category={category} onCellPress={props.onCellPress} />
            <RenderTableRow index={3} scores={scores} category={category} onCellPress={props.onCellPress} />
            <RenderTableRow index={2} scores={scores} category={category} onCellPress={props.onCellPress} />
            <RenderTableRow index={1} scores={scores} category={category} onCellPress={props.onCellPress} />
            <RenderTableRow index={0} scores={scores} category={category} onCellPress={props.onCellPress} />
        </>
    );
};

interface TableRowProps{
    index: number;
    scores: number[];
    category: string
    onCellPress: (scoreIndex:number)=>void;
}

const RenderTableRow = (props: TableRowProps) => {
    const scores = props.scores
    const category = props.category
    return (
        <TableRow>
            {scores.map((score: number, scoreIndex: number) => {
                return (
                    <TableData key={`col-${props.index}-row-${scoreIndex}`} index={scoreIndex} onClick={()=>props.onCellPress(scoreIndex)}>
                        {score === props.index + 1 && (
                            <TableDotWrapped className={`dot-${category}-${scoreIndex}`} />
                        )}
                    </TableData>
                );
            })}
        </TableRow>
    );
};

const TableDotWrapped = (props: { className: string }) => {
    return (
        <TableDotWrapper>
            <TableDot className={props.className} />
        </TableDotWrapper>
    );
};

interface LinesProps{
    scores: number[];
    category: string;
}

const Lines = (props: LinesProps) =>{
    const category = props.category;
    const scores = props.scores;
    let prevScoreIndex = 0;
    let newPrevScoreIndex = 0;
    const lines= scores.map((score, index) => {
        prevScoreIndex = newPrevScoreIndex;
        if (scores[prevScoreIndex] !== 0 && scores[index] !== 0) {
            newPrevScoreIndex = index;
        }
        else if(scores[prevScoreIndex]===0&&score===0){
            newPrevScoreIndex = prevScoreIndex+1
        }

        return (
            <LineTo delay={(200+(180*index))} from={`dot-${category}-${prevScoreIndex}`} to={`dot-${category}-${index}`} borderWidth={2}
                    borderStyle={"solid"} toAnchor={"center center"} fromAnchor={"center center"}
                    borderColor={YELLOW_500} key={`line-${category}-${prevScoreIndex}-to-${index}`} />
        );


    });
    return <>{lines}</>
}

const RenderTableLines = (props: TableLinesProps) => {
    return (
        <div>
            <Lines scores={props.scores} category={props.category}/>
        </div>
    );
};
