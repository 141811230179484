import React, { useContext, useState } from "react";
import { Content } from "../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { HeadingL } from "../components/Heading";
import { Error, FieldGroup, Form, Input, Label } from "../components/Forms";
import * as yup from 'yup';
import styled from "styled-components";
import { OutlineButton, PrimaryButton } from "../components/atoms/Button";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../hooks/useFirebase";
import { LoadingContext } from "../context/LoadingContext";
import { NEUTRAL_200 } from "../constants/colours";

const Schema = yup.object({
    email: yup.string().email("Email is not valid").required("Required"),
})

const ButtonGroup = styled.div`
    button:first-of-type {
        margin-bottom: 8px;
    }
`;

const Sent = styled.div`
    background-color: ${NEUTRAL_200};
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 32px;
`;

export default function ForgotPassword() {
    const history = useHistory();
    const auth = useFirebase().auth();
    const [loading, setLoading] = useContext(LoadingContext);
    const [sent, setSent] = useState<boolean>(false);

    const submit = async (values: {email: string}) => {
        try {
            setLoading(true);
            await auth.sendPasswordResetEmail(values.email);
            setSent(true);
        } catch (e) {
            console.log('err', e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Content>
            <Row>
                <Col xs>
                    <HeadingL>Reset your password</HeadingL>
                    <Form initialValues={{email: ""}} schema={Schema} onSubmit={submit}>
                        <FieldGroup>
                            <Label forName={"email"}>email address</Label>
                            <Input name={"email"} type={"email"}/>
                            <Error forName={"email"}/>
                        </FieldGroup>
                        {sent && <Sent>Request submitted. We've sent a you an email with a link to create a new password.</Sent>}
                        <ButtonGroup>
                            <PrimaryButton type={"submit"} disabled={loading || sent}>Send me a link</PrimaryButton>
                            <OutlineButton onClick={history.goBack} disabled={loading}>Return to login</OutlineButton>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
        </Content>
    )
}