export enum UserRole {
    administrator = "administrator",
    manager = "manager",
    tutor = "tutor",
    student = "student"
}

export interface User {
    uid: string;
    name: string;
    email: string;
    role: UserRole;
}

export interface UserRef {
    uid: string;
    name: string;
}
