import styled from "styled-components";
import { NEUTRAL_300 } from "../../constants/colours";

export const Divider = styled.div`
    border-style: solid;
    border-color: ${NEUTRAL_300};
    border-width: 1px;
    border-top-width: 0;
    width: 100%;
    margin: 12px 0;
`
