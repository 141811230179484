import styled from 'styled-components';
import { HEADER_HEIGHT } from "../constants/styles";

const Main = styled.main`
  padding: 0px 15px 36px 15px;
  margin-top: ${HEADER_HEIGHT};
    
  @media screen and (min-width:768px){
    max-width: 768px;
    margin: auto;
    margin-top: ${HEADER_HEIGHT};
  }
`;

export const Content: React.FC = ({ children }) => <Main>{children}</Main>;
