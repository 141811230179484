import { Col, Row } from "react-styled-flexboxgrid";
import { Error, FieldGroup, Form, Input, Label, Text } from "../../../../components/Forms";
import * as yup from "yup";
import { Content } from "../../../../components/Content";
import { HeadingL } from "../../../../components/Heading";
import { Divider } from "../../../../components/atoms/Divider";
import CourseToggleSelect from "../../../../components/CourseToggleSelect";
import { PrimaryButton } from "../../../../components/atoms/Button";
import { useFirebase } from "../../../../hooks/useFirebase";
import { useHistory } from "react-router-dom";
import { Collections } from "../../../../constants/collections";
import { UserRole } from "../../../../types/User";
import { IconSource } from "../../../../components/atoms/Icon";
import React, { useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import Action from "../../../../components/Action";
import createStudentEnrolment from "../../../../utils/createStudentEnrolment";
import { Course } from "../../../../types/Course";


const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    phone: yup.string().required("Required"),
    address: yup.string().required("Required"),
    notes: yup.string(),
});

const ActionPadded = styled(Action)`
padding-top:32px;
`;

export default function AddNewStudent() {
    const firestore = useFirebase().firestore();
    const [selectedCourses, setSelectedCourses] = useState<any[]>([]);
    const history = useHistory();
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        notes: "",
    };

    const [courses, loading] = useCollectionDataOnce<Course>(firestore.collection(Collections.courses), { idField: "id" });
    const onSubmit = (values: any) => {
        values.role = UserRole.student;
        const toEnrol = courses!.filter((course) => selectedCourses.includes(course.name))

        firestore.collection(Collections.userProfiles).add(values)
            .then((docRef) => {
                if (toEnrol.length) {
                    const enrolments = toEnrol.map((course) => (
                        createStudentEnrolment(course!, docRef.id)
                    ))
                    enrolments.forEach((enrolment) => {
                        firestore.collection(Collections.enrolments).add(enrolment).then((docRef) => {
                        })
                    })
                }
                history.replace(`/profiles/students/${docRef.id}`);
            }).catch((error) => {
            console.log("Error adding user profile:", error);
        });

    };

    const onStudentsPress = () => {
        history.push(`/profiles/students`);
    };

    const onCourseClick = (courseName: any) => {
        let newSelectedCourses = [...selectedCourses];

        if (!selectedCourses.length) {
            newSelectedCourses.push(courseName);
            setSelectedCourses(newSelectedCourses);
            return;
        }

        if (selectedCourses.includes(courseName)) {
            newSelectedCourses.splice(newSelectedCourses.indexOf(courseName), 1);
            setSelectedCourses(newSelectedCourses);
            return;
        }

        newSelectedCourses.push(courseName);
        setSelectedCourses(newSelectedCourses);
    };

    if (loading) {
        return <Loader/>;
    }

    return (
        <Content>
            <Row>
                <Col>
                    <ActionPadded text={"Students"} icon={IconSource.back} onClick={onStudentsPress}/>
                </Col>
            </Row>
            <Form initialValues={initialValues} schema={validationSchema} onSubmit={(values) => onSubmit(values)}>
                <Row>
                    <Col xs>
                        <HeadingL>Add new student</HeadingL>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <h4>Student details</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"firstName"}>First Name</Label>
                            <Input name={"firstName"} type={"text"}/>
                            <Error forName={"firstName"}/>
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"lastName"}>Last Name</Label>
                            <Input name={"lastName"} type={"text"}/>
                            <Error forName={"lastName"}/>
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"email"}>Email Address</Label>
                            <Input name={"email"} type={"email"}/>
                            <Error forName={"email"}/>
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"phone"}>Contact Number</Label>
                            <Input name={"phone"} type={"number tel"}/>
                            <Error forName={"phone"}/>
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"address"}>Address</Label>
                            <Input name={"address"} type={"text"}/>
                            <Error forName={"address"}/>
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"notes"}>Notes</Label>
                            <Text name={"notes"} type={"text"} placeholder={"Add a note about this student..."}/>
                            <Error forName={"notes"}/>
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <Divider/>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <h4>Student enrolments</h4>
                    </Col>
                </Row>

                {
                    courses?.map((course, index) => {
                        const selected = selectedCourses.includes(course.name);
                        return (
                            <Row key={course.id}>
                                <Col xs>
                                    <Label forName={"courses"}>{course.name}</Label>
                                    <CourseToggleSelect course={course.name}
                                                        icon={selected ? IconSource.save : IconSource.new}
                                                        onClick={() => onCourseClick(course.name)}
                                                        selected={selected}/>
                                </Col>
                            </Row>
                        );
                    })
                }
                <Row>
                    <Col xs>
                        <PrimaryButton type={"submit"}>Save & Continue</PrimaryButton>
                    </Col>
                </Row>
            </Form>
        </Content>
    );
}
