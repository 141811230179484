import { FirebaseUser, useFirebase } from "./useFirebase";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "../types/User";

export const UserContext = createContext<[User | null, boolean]>([null, true]);

export const UserProvider: React.FC = ({ children }) => {
    const firebase = useFirebase();

    const [firebaseUser, userLoading] = useAuthState(firebase.auth());
    const [user, setUser] = useState<User | null>(null);
    const [loadingClaims, setLoadingClaims] = useState<boolean>(true);

    useEffect(() => {
        if (!firebaseUser && !userLoading) {
            setLoadingClaims(false);
        }
    }, [userLoading, firebaseUser]);

    useEffect(() => {
        if (!firebaseUser) {
            setUser(null);
        } else {
            const withClaims = async (firebaseUser: FirebaseUser) => {
                const result = await firebaseUser.getIdTokenResult();
                let claims = result.claims;

                if (!claims.role) {
                    const refreshedToken = await firebaseUser.getIdTokenResult(true);
                    claims = refreshedToken.claims;
                }

                const user = {
                    uid: firebaseUser?.uid,
                    name: firebaseUser?.displayName,
                    email: firebaseUser?.email,
                    role: claims.role,
                } as User;

                setUser(user);
                setLoadingClaims(false);
            };

            withClaims(firebaseUser);
        }
    }, [firebaseUser]);

    return (
        <UserContext.Provider value={[user, loadingClaims]}>
            {children}
        </UserContext.Provider>
    );
};

export const useAuth = (): [User | null, boolean] => {
    return useContext(UserContext);
};
