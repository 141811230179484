import React from "react";
import { Content } from "../../../components/Content";
import { Course } from "../../../types/Course";
import Loader from "../../../components/Loader";
import CourseToggleSelect from "../../../components/CourseToggleSelect";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import { IconSource } from "../../../components/atoms/Icon";
import { useBCITOCourses, useICONIQCourses } from "../../../hooks/useCourses";
import { Col, Row } from "react-styled-flexboxgrid";
import { useHistory } from "react-router-dom";
import { HeadingD } from "../../../components/Heading";
import ListItemLabel from "../../../components/ListItemLabel";

export default function AllCourses() {
    const [bcitoCourses, loadingBCITOCourses] = useBCITOCourses();
    const [iconiqCourses, loadingIconiqCourses] = useICONIQCourses();
    const history = useHistory();

    if (loadingBCITOCourses || loadingIconiqCourses) {
        return <Loader />;
    }

    const onCourseClick = (course: Data<Course>) => {
        history.push(`/courses/${course.id}`);
    };

    return (
        <Content>
            <Row>
                <Col xs>
                    <HeadingD>Courses</HeadingD>
                    {!!bcitoCourses && (
                        <>
                            <ListItemLabel>BCITO Apprenticeships</ListItemLabel>
                            {bcitoCourses?.map((course: Data<Course>) => (
                                <CourseToggleSelect key={course.id}
                                                    course={course.name}
                                                    selected={false}
                                                    icon={IconSource.next}
                                                    onClick={() => onCourseClick(course)}
                                />
                            ))}
                        </>
                    )}
                    {!!iconiqCourses && (
                        <>
                            <ListItemLabel>ICONIQ</ListItemLabel>
                            {iconiqCourses?.map((course: Data<Course>) => (
                                <CourseToggleSelect key={course.id}
                                                    course={course.name}
                                                    selected={false}
                                                    icon={IconSource.next}
                                                    onClick={() => onCourseClick(course)}
                                />
                            ))}
                        </>
                    )}
                </Col>
            </Row>
        </Content>
    );
}