import styled from 'styled-components';
import { NEUTRAL_100, NEUTRAL_900 } from "../constants/colours";
import React, {useEffect, useState} from "react";
import { Drawer } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from "../constants/routes";
import { HEADING_SMALL } from "../constants/typography";
import { Divider } from "./atoms/Divider";
import AmaLogo from "../resources/ama@2x.png";
import IconiqLogo from "../resources/iconiq-group@2x.png";
import { useFirebase } from "../hooks/useFirebase";
import { HEADER_HEIGHT, NO_HIGHLIGHT } from "../constants/styles";

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${HEADER_HEIGHT};
    z-index: 1400;
`;

const Header = styled.header`
  background-color: ${NEUTRAL_900};
  color: white;
  height: 50px;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
`;

const StyledHomeIcon = styled(HomeIcon)`
    cursor: pointer;
    
    & .MuiIcon-root {
        height: 12px;
        width: 12px;
    };
`;

const StyledMenuIcon = styled(MenuIcon)`
    cursor: pointer;
`;

const StyledDrawer = styled(Drawer)`

    & .MuiDrawer-paper {
        padding: 0 16px;
    }
    
    & .MuiDrawer-paperAnchorTop {
        background-color: ${NEUTRAL_900};
        color: ${NEUTRAL_100};
        top: 70px;
        overflow-x: hidden;
    }
`;

const DrawerItem = styled.span`
    cursor: pointer;
    ${HEADING_SMALL};
    padding: 16px 0;
    ${NO_HIGHLIGHT};
`;

const Iconiq = styled.div`
    background-image: url(${IconiqLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 160px;
    width: 50%;
    margin-right: 8px;
    height: 120px;
`;

const Ama = styled.div`
    background-image: url(${AmaLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 160px;
    width: 50%;
    height: 120px;
`;

const Logos = styled.div`
    display: flex;
`;

export const NavBar = () => {
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(location.pathname !== "/login" && location.pathname !== "/forgot-password" );
    const auth = useFirebase().auth();

    useEffect(()=>{
        setShowMenu(location.pathname !== "/login" && location.pathname !== "/forgot-password")
    }, [location])

    const toggleDrawer = () => {
        setOpen((state) => !state)
    }

    const onDrawerNav = (path: string) => () => {
        setOpen(false);
        history.push(path);
    }

    const onLogout = () => {
        setOpen(false);
        auth.signOut();
    }

    return (
        <Wrapper>
            <Header>
                <StyledHomeIcon onClick={() => {
                    setOpen(false);
                    history.push(Routes.students.overview)
                }}/>
                {showMenu && <StyledMenuIcon onClick={toggleDrawer}/>}
            </Header>
            {showMenu && (
                <StyledDrawer anchor={'top'} open={open} onClose={toggleDrawer}>
                    <DrawerItem onClick={onDrawerNav(Routes.students.home)}>Find a student</DrawerItem>
                    <DrawerItem onClick={onDrawerNav(Routes.courses)}>View courses</DrawerItem>
                    <DrawerItem onClick={onDrawerNav(Routes.staff.list)}>ICONIQ staff</DrawerItem>
                    <DrawerItem onClick={onDrawerNav(Routes.myProfile)}>My account</DrawerItem>
                    <DrawerItem onClick={onLogout}>Log out</DrawerItem>
                    <Divider/>
                    <Logos>
                        <Iconiq/>
                        <Ama/>
                    </Logos>
                </StyledDrawer>
            )}
        </Wrapper>
    )
};
