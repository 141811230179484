import styled from "styled-components";
import { NEUTRAL_200, NEUTRAL_300 } from "../constants/colours";


const ButtonsContainer = styled.div`
    display: flex;
    background-color: ${NEUTRAL_200};
    border-color: ${NEUTRAL_300};
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    height: 32px;
`

const ToggleButton = styled.button<any>`
    background-color: ${props => props.selected?"white": NEUTRAL_200};
    flex: 1;
    border: none;
    border-radius: 8px;
    
`

type ToggleButtonValues = {
    text: string;
    value: string;
}

type ToggleButtonGroupProps = {
    buttons: ToggleButtonValues[];
    selectedButton: string;
    onClick: (value: string) => void;
}

export default function ToggleButtonGroup(props: ToggleButtonGroupProps){

    const onButtonClick = (value: string) =>{
        props.onClick(value)
    }

    return(
        <ButtonsContainer>
            {props.buttons.map((button, index)=>(
                <ToggleButton key={`toggle-button-${index}`} selected={props.selectedButton === button.value} onClick={()=>onButtonClick(button.value)}>{button.text}</ToggleButton>
            ))
            }
        </ButtonsContainer>
    )
}
