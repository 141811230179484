import React from "react";
import { Route, Switch } from "react-router-dom";
import AllCourses from "./allCourses/AllCourses";
import CourseDetail from "./courseDetail/CourseDetail";
import ModuleDetail from "./courseDetail/moduleDetail/ModuleDetail";
import StandardDetail from "./courseDetail/moduleDetail/standardDetail/StandardDetail";
import TaskDetail from "./courseDetail/moduleDetail/standardDetail/taskDetail/TaskDetail";

export default function Courses() {
    return (
        <Switch>
            <Route exact path={"/courses/:id"}>
                <CourseDetail />
            </Route>
            <Route path={"/courses/:id/modules"}>
                <ModuleDetail />
            </Route>
            <Route exact path={"/courses/:id/standards/:standardId"}>
                <StandardDetail />
            </Route>
            <Route exact path={"/courses/:id/tasks/:taskId"}>
                <TaskDetail />
            </Route>
            <Route path={"/courses"}>
                <AllCourses />
            </Route>
        </Switch>
    );
}
