import Dialog from "@material-ui/core/Dialog";
import React, { Ref, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { Form, Text } from "./Forms";
import { OutlineButton, PrimaryButton } from "./atoms/Button";
import SelfAssessmentFields, { SelfAssessmentSchema, SelfAssessmentValues } from "./SelfAssessmentFields";
import * as yup from "yup";
import { useFirebase } from "../hooks/useFirebase";
import { DAILY_ASSESSMENT_COMPLETED } from "../constants/constants";
import { Collections } from "../constants/collections";
import { SelfAssessment } from "../types/SelfAssessment";
import firebase from "firebase/app";
import { useAuth } from "../hooks/useAuth";
import { useStaffProfile } from "../hooks/useProfiles";

const StyledDialog = styled(Dialog)`
    .MuiDialog-container{
        padding-top: 36px;
        padding-bottom: 36px;
    }
    
    .MuiDialog-paper{
        padding: 16px;
        max-height: calc(70vh);
        max-width: 80%;
        width:100%;
        
    @media screen and (min-width:768px){
        max-width: 500px;
        }
    }
`;

const ButtonGroup = styled.div`
    margin-top:32px;
    
`;

const MarginButton = styled(PrimaryButton)`
    margin-bottom: 8px;`

interface DialogProps {
    onAssessmentCompleted?: () => void;
}

export interface DialogRef {
    show: () => void;
    hide: () => void;
}

function SelfAssessmentDialog(props: DialogProps, ref: Ref<DialogRef>) {
    const [user] = useAuth();
    const [profile] = useStaffProfile(user?.uid || "");
    const [open, setOpen] = useState<boolean>(false);
    const [firstPage, setFirstPage] = useState<boolean>(true);
    const [values, setValues] = useState<SelfAssessmentValues>({
        tahaTinana: null,
        tahaHinengaro: null,
        tahaWairua: null,
        tahaWhanau: null,
    });
    const db = useFirebase().firestore();

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide
    }))

    const show = () => {
        setOpen(true);
    }

    const hide = () => {
        setOpen(false);
    }

    const handleNoteSubmit = async ({ note }: Pick<SelfAssessment, 'note'>) => {
        const profileId = profile ? profile[0].id : "";
        await db.collection(Collections.assessments).add({
            pid: profileId,
            date: firebase.firestore.FieldValue.serverTimestamp(),
            ...values,
            note: note || "",
        })
        window.localStorage.setItem(DAILY_ASSESSMENT_COMPLETED, new Date().toISOString());
        hide();
        props.onAssessmentCompleted!();
    };

    const handleMetricSubmit = (values: SelfAssessmentValues) => {
        if (values.tahaHinengaro || values.tahaTinana || values.tahaWairua || values.tahaWhanau) {
            setValues(values);
            setFirstPage(false);
        }
    }

    return (
        <>
            <StyledDialog onClose={() => null} open={open}>
                {firstPage ?
                    <>
                        <h4>How are you feeling today?</h4>
                        <Form initialValues={{
                            tahaTinana: null,
                            tahaHinengaro: null,
                            tahaWairua: null,
                            tahaWhanau: null
                        }} schema={SelfAssessmentSchema} onSubmit={handleMetricSubmit}>
                            <SelfAssessmentFields/>
                            <MarginButton type={"submit"}>Confirm self assessment</MarginButton>
                            <OutlineButton onClick={hide}>Not now</OutlineButton>
                        </Form>
                    </>
                    :
                    <>
                        <h4>Would you like to add a note about your Te Whare Tapa Whā?</h4>
                        <Form initialValues={{
                            note: ""
                        }} onSubmit={handleNoteSubmit} schema={yup.object({ note: yup.string() })}>
                            <label>ADD NOTE</label>
                            <Text name={"note"} type={""}/>
                            <ButtonGroup>
                                <MarginButton type={"submit"}>Save assessment</MarginButton>
                                <OutlineButton onClick={() => setFirstPage(true)}>Back</OutlineButton>
                            </ButtonGroup>
                        </Form>
                    </>}
            </StyledDialog>
        </>
    )
}

export default React.forwardRef(SelfAssessmentDialog);
