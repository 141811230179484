import styled from 'styled-components';
import Button from "@material-ui/core/Button";
import { NEUTRAL_100, NEUTRAL_300, NEUTRAL_900, RED_500 } from "../../constants/colours";

export const BaseButton = styled(Button)`
  .MuiButton-root{
    text-transform: none;
    font-family: 'Questrial', sans-serif!important  ;
  }
  width: 100%;
  font-family: 'Questrial', sans-serif;
  color: ${NEUTRAL_100};
  background-color: ${NEUTRAL_900};
  font-size: 18px;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  text-transform: none;

  &:focus {
    outline: none;
  }
`;

export const PrimaryButton = styled(BaseButton)`
    background-color: ${props => props.disabled ? NEUTRAL_300 : NEUTRAL_900 };
   
    &:hover {
        background-color: ${props => props.disabled ? NEUTRAL_300 : NEUTRAL_900 };
    }
`;

export const OutlineButton = styled(BaseButton)`
    background-color: ${NEUTRAL_100};
    color: ${props => props.disabled ? NEUTRAL_300 : NEUTRAL_900 };
    border: 1px solid ${NEUTRAL_300};
    
    &:hover {
        background-color: ${NEUTRAL_100};
    }Í
`;
export const RedOutlineButton = styled(BaseButton)`
    background-color: ${NEUTRAL_100};
    color: ${RED_500};
    border: 1px solid ${NEUTRAL_300};
    
    &:hover {
        background-color: ${NEUTRAL_100};
    }Í
`;

export const RedButton = styled(BaseButton)`
    background-color: ${RED_500};
   
    &:hover {
        background-color: ${props => props.disabled ? NEUTRAL_300 : NEUTRAL_900 };
    }
`;