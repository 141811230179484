import { Content } from '../components/Content';
import { Error, FieldGroup, Form, FormHelpers, Input, Label } from '../components/Forms';
import { Col, Row } from 'react-styled-flexboxgrid';
import { OutlineButton, PrimaryButton } from '../components/atoms/Button';
import { HeadingD } from '../components/Heading';
import * as yup from 'yup';
import { useFirebase } from '../hooks/useFirebase';
import { useAuth } from '../hooks/useAuth';
import { Redirect, useHistory } from 'react-router-dom';
import React, { useRef } from "react";
import styled from "styled-components";
import { FormikProps } from "formik";
import Routes from "../constants/routes";
import extractQueryParams from "../utils/extractQueryParams";

interface LoginForm {
    email: string;
    password: string;
}

const LoginSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
    password: yup.string().required('Required').min(6, "Password too short"),
});

const ButtonGroup = styled.div`
    button:first-of-type {
        margin-bottom: 8px;
    }
`;

export const Login = () => {
    const initial: LoginForm = { email: '', password: '' };
    const firebase = useFirebase();
    const auth = firebase.auth();
    const [user] = useAuth();
    const formRef = useRef<FormikProps<any>>(null);
    const history = useHistory();

    const submit = async (values: LoginForm, actions: FormHelpers<LoginForm>) => {
        try {
            // console.log('signing in...');
            await auth.signInWithEmailAndPassword(
                values.email,
                values.password
            );
        } catch (e) {
            console.log(e);
            actions.setStatus({
                error: {
                    message: e.code,
                },
            });
        }
    };

    const handleEmailLink = async ({ email, password }: LoginForm, actions: FormHelpers<LoginForm>) => {
        try {
            // store params before redirect after login
            const role = extractQueryParams("user", window.location.search);
            const firstName = extractQueryParams("firstName", window.location.search);
            const lastName = extractQueryParams("lastName", window.location.search);
            const phone = extractQueryParams("phone", window.location.search);

            const res = await auth.signInWithEmailLink(email, window.location.href)

            if (res.user) {
                await res.user.updatePassword(password);
                await res.user.updateProfile({ displayName: firstName });

                const authorise = firebase.functions().httpsCallable("authorise");
                await authorise({
                    uid: res.user.uid,
                    role,
                    email,
                    firstName,
                    lastName,
                    phone
                })

                // force refresh of user after updating
                window.location.reload();
            }
        } catch (e) {
            console.log('err', e);
            actions.setStatus({ error: { message: e.message } })
        }
    }

    if (user) {
        // todo change login redirect to depend on user role
        return <Redirect to={Routes.students.home}/>;
    }

    if (auth.isSignInWithEmailLink(window.location.href)) {
        const initial = {
            email: "",
            password: ""
        }

        return (
            <Content>
                <Row center={"xs"}>
                    <Col xs>
                        <HeadingD>Confirm email & create a password</HeadingD>
                    </Col>
                </Row>
                <Row center={"xs"}>
                    <Col xs>
                        <Form initialValues={initial} schema={LoginSchema} onSubmit={handleEmailLink}>
                            <FieldGroup>
                                <Label forName={"email"}>Email Address</Label>
                                <Input name={"email"} type={"email"}/>
                                <Error forName={"email"}/>
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"password"}>Password</Label>
                                <Input name={"password"} type={"password"}/>
                                <Error forName={"password"}/>
                            </FieldGroup>
                            <Error/>
                            <PrimaryButton type={"submit"}>Submit</PrimaryButton>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

    return (
        <Content>
            <Row center="xs">
                <Col>
                    <HeadingD>Log In</HeadingD>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs>
                    <Form initialValues={initial} schema={LoginSchema} onSubmit={submit} innerRef={formRef}>
                        <FieldGroup>
                            <Label forName="email">Email Address</Label>
                            <Input name="email" type="email"/>
                            <Error forName="email"/>
                        </FieldGroup>
                        <FieldGroup>
                            <Label forName="password">Password</Label>
                            <Input name="password" type="password"/>
                        </FieldGroup>
                        <Error/>
                        <ButtonGroup>
                            <PrimaryButton type="submit">Log In</PrimaryButton>
                            <OutlineButton onClick={() => history.push(Routes.forgotPassword)}>Forgot
                                password</OutlineButton>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
        </Content>
    );
};
