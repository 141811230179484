import { Course, Enrolment, EnrolmentTask } from "../types/Course";

export default function createStudentEnrolment(course: Course, studentId: string): Enrolment {
    let tasks: {[taskId: string]: EnrolmentTask }= {};


    return {
        course: course.name,
        studentId: studentId,
        courseId: course.id,
        tasks,
        id: ""
    };
}
