import { useCollectionDataOnce, useDocumentData } from "react-firebase-hooks/firestore";
import useCollectionRef from "./useCollectionRef";
import { Collections } from "../constants/collections";
import { Course, CourseType, Module, Standard } from "../types/Course";

export function useBCITOCourses() {
    const query = useCollectionRef(Collections.courses).where("type", "==", CourseType.BCITO);
    return useCollectionDataOnce<Course>(query, { idField: "id" });
}

export function useICONIQCourses() {
    const query = useCollectionRef(Collections.courses).where("type", "==", CourseType.ICONIQ);
    return useCollectionDataOnce<Course>(query, { idField: "id" });
}

export function useCourse(id: string) {
    const courseRef = useCollectionRef(Collections.courses).doc(id);
    return useDocumentData<Course>(courseRef, { idField: "id" });
}

export const useStandard = (courseId: string, standardNumber: number): [Course | undefined, Module | undefined, Standard | undefined, boolean] => {
    const [course, loadingCourse] = useCourse(courseId);
    let standard: Standard | undefined = undefined;
    let module: Module | undefined = undefined;

    if (!loadingCourse && !isNaN(standardNumber)) {
        course?.modules.forEach(m => {
            const filteredStandards: Standard[] = m.standards.filter(s => {
                return s.number === standardNumber;
            });

            if (filteredStandards.length > 0) {
                standard = filteredStandards[0];
                module = m;
                return;
            }
        });
    }

    return [course, module, standard, loadingCourse];
};
