import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { FirebaseProvider } from './hooks/useFirebase';
import { UserProvider } from './hooks/useAuth';
import { StylesProvider } from '@material-ui/core/styles';

ReactDOM.render(
    <React.StrictMode>
        <FirebaseProvider>
            <UserProvider>
                <Router>
                    <StylesProvider injectFirst>
                        <App/>
                    </StylesProvider>
                </Router>
            </UserProvider>
        </FirebaseProvider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
