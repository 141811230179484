import React from "react";
import { Content } from "../../../../../../components/Content";
import { Col, Row } from "react-styled-flexboxgrid";
import { useStandard } from "../../../../../../hooks/useCourses";
import { Task } from "../../../../../../types/Course";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader";
import ListItemLabel from "../../../../../../components/ListItemLabel";
import styled from "styled-components";
import { NEUTRAL_200 } from "../../../../../../constants/colours";
import Breadcrumb from "../../../../../../components/Breadcrumb";
import Checklist from "../../../../../../components/Checklist";
import { OutlineButton } from "../../../../../../components/atoms/Button";
import { useEnrolment } from "../../../../../../hooks/useEnrolments";

const HeadingWrapper = styled.div`
    padding-bottom: 16px;
    border-bottom: 1px solid ${NEUTRAL_200};
    margin-bottom: 16px;
    h3 {
        margin-bottom: 8px;
    }
`;


export default function EnrolmentDetail() {
    const { id, standardId, enrolmentId } = useParams<{ id: string, standardId: string, enrolmentId?: string }>();
    const [course, , standard, loadingStandard] = useStandard(id, Number(standardId));
    const [enrolment, loadingEnrolment] = useEnrolment(enrolmentId!);
    const history = useHistory();
    if ((!standard && loadingStandard) || loadingEnrolment) {
        return <Loader />;
    }

    const allCompleted = standard?.tasks.every((t) => (enrolment?.tasks[t.id] || {}).completed);
    const onTaskClick = (task: Task) => {
        history.push(`/enrolments/${enrolmentId}/${course?.id}/${task.id}`);
    };

    const onCourseClick = () => {
        history.goBack();
    };


    const onMarkAllCompletedClick = () => {
        const tasks = { ...enrolment?.tasks };
        standard?.tasks.forEach((task) => {
            tasks[task.id] = {
                taskId: task.id,
                completed: true,
                notes: (tasks[task.id] || {}).notes || []
            };
        });
        enrolment?.ref!.update({ tasks });
    };

    const onMarkAllUncompleted = () => {
        const tasks = { ...enrolment?.tasks };
        standard?.tasks.forEach((task) => {
            tasks[task.id] = {
                taskId: task.id,
                completed: false,
                notes: (tasks[task.id] || {}).notes || []
                    
            };
        });
        enrolment?.ref!.update({tasks});
    }


    return (
        <Content>
            <Row>
                <Col xs>
                    <Breadcrumb text={"Standards"} onClick={onCourseClick} />
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <HeadingWrapper>
                        <h3>{standard?.name}</h3>
                        <ListItemLabel>{standard?.number}</ListItemLabel>
                    </HeadingWrapper>
                </Col>
            </Row>
            <Row>
                <Col xs>
                    {standard?.tasks.map(task => {
                        const selected = (enrolment?.tasks[task.id] || {}).completed || false;
                        return (
                            <div onClick={() => onTaskClick(task)} key={task.id}>
                                <Checklist text={task.name}
                                           readOnly

                                           selected={selected}
                                           taskNumber={task.number}
                                />
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <Row>
                <Col xs>
                    {allCompleted ? (
                        <OutlineButton onClick={onMarkAllUncompleted}>Mark all as uncompleted</OutlineButton>
                        ):(
                        <OutlineButton onClick={onMarkAllCompletedClick}>Mark all as completed</OutlineButton>
                    )}
                </Col>
            </Row>
        </Content>
    );
}
