import { SelfAssessment } from "./Forms";
import React from "react";
import * as yup from "yup";

export type SelfAssessmentValues = {
    tahaTinana: number | null,
    tahaHinengaro: number | null,
    tahaWairua: number | null,
    tahaWhanau: number | null,
}

export const SelfAssessmentSchema = yup.object({
    tahaTinana: yup.number().nullable(true),
    tahaHinengaro: yup.number().nullable(true),
    tahaWairua: yup.number().nullable(true),
    tahaWhanau: yup.number().nullable(true),
})

interface Props {
    disabled?: boolean;
}
export default function SelfAssessmentFields(props: Props) {
    return (
        <>
            <SelfAssessment label={"Taha Tinana (Physical)"} name={"tahaTinana"} disabled={props.disabled}/>
            <SelfAssessment label={"Taha Hinengaro (Mental)"} name={"tahaHinengaro"} disabled={props.disabled}/>
            <SelfAssessment label={"Taha Wairua (Spiritual)"} name={"tahaWairua"} disabled={props.disabled}/>
            <SelfAssessment label={"Taha Whanau (Relationships)"} name={"tahaWhanau"} disabled={props.disabled}/>
        </>
    )
}
