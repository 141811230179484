import React, { createContext, useState } from "react";

export const LoadingContext = createContext<[boolean, (l: boolean) => void]>([
    false,
    () => null
]);

export const LoadingProvider = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);

    return <LoadingContext.Provider value={[loading, setLoading]} {...props}/>
}

