import { UserRef } from "./User";
import firebase from "firebase/app";

export interface Course {
    id: string;
    name: string;
    modules: Module[];
    type: CourseType;
}

export enum CourseType {
    BCITO = "BCITO",
    ICONIQ = "ICONIQ"
}

export interface Module {
    name: string;
    standards: Standard[];

}

export interface Standard {
    name: string;
    formattedName: string;
    number: number;
    tasks: Task[]
}
export interface EnrolmentTask {
    taskId: string;
    completed: boolean;
    notes: Note[]
}

export interface Note {
    createdAt?: firebase.firestore.Timestamp|firebase.firestore.FieldValue;
    note: string;
    createdBy: UserRef;
}

export interface Enrolment {
    id: string;
    course: string;
    studentId: string;
    tasks: {[taskId: string] : EnrolmentTask}
    courseId: string;
    ref?: firebase.firestore.DocumentReference;
}


export interface Task {
    completed: boolean;
    name: string;
    id: string;
    number: number;
    formattedName: string;
}
