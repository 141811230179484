import { Redirect, Route, Switch } from "react-router-dom";
import React, { useContext } from "react";
import { GlobalStyle } from "./GlobalStyle";
import { Login } from "./routes/Login";
import { NavBar } from "./components/NavBar";
import { useAuth } from "./hooks/useAuth";
import Routes from "./constants/routes";
import AddNewStudent from "./routes/profiles/students/addNewStudent/AddNewStudent";
import Student from "./routes/profiles/students/student/Student";
import SelfAssessmentHistory from "./components/SelfAssessmentHistory";
import Students from "./routes/profiles/students/Students";
import Courses from "./routes/courses/Courses";
import StaffList from "./routes/profiles/staff/StaffList";
import StaffProfile from "./routes/profiles/staff/StaffProfile";
import StaffInvite from "./routes/profiles/staff/StaffInvite";
import { LoadingContext, LoadingProvider } from "./context/LoadingContext";
import OverlayLoader from "./components/OverlayLoader";
import ForgotPassword from "./routes/ForgotPassword";
import Enrolment from "./routes/profiles/students/student/enrolment/Enrolment";
import EnrolmentDetail from "./routes/profiles/students/student/enrolment/enrolmentDetail/EnrolmentDetail";
import EnrolmentTaskDetail
    from "./routes/profiles/students/student/enrolment/enrolmentDetail/enrolmentTaskDetail/EnrolmentTaskDetail";
import MyProfile from "./routes/profiles/MyProfile";

function App() {
    const [appLoading] = useContext(LoadingContext);
    const loading = useAuth()[1];

    if (loading) {
        return null;
    }

    return (
        <>
            <GlobalStyle />
            <OverlayLoader loading={appLoading} />
            <NavBar />
            <LoadingProvider>
                <Switch>
                    <Route path={Routes.login} component={Login} />
                    <Route path={Routes.forgotPassword} component={ForgotPassword} />
                    <AuthenticatedRoute path="/">
                        <Switch>
                            <Route path={Routes.courses} component={Courses} />
                            <Route path={Routes.students.create} component={AddNewStudent} />
                            <Route exact path={Routes.enrolments.overview} component={Enrolment} />
                            <Route exact path={Routes.enrolments.standard} component={EnrolmentDetail} />
                            <Route exact path={Routes.enrolments.task} component={EnrolmentTaskDetail} />
                            <Route exact path={Routes.students.profile} component={Student} />
                            <Route exact path={Routes.students.assessments} component={SelfAssessmentHistory} />
                            <Route path={Routes.students.home} component={Students} />
                            <Route path={Routes.staff.invite} component={StaffInvite} />
                            <Route path={"/profiles/staff/:id/history"} component={SelfAssessmentHistory} />
                            <Route path={"/profiles/staff/:id"} component={StaffProfile} />
                            <Route path={Routes.staff.list} component={StaffList} />
                            <Route path={Routes.myProfile} component={MyProfile} />
                            <Redirect to={Routes.staff.list} />
                        </Switch>
                    </AuthenticatedRoute>
                </Switch>
            </LoadingProvider>
        </>
    );
}

const AuthenticatedRoute: React.FC<{ path: string }> = ({
                                                            children,
                                                            ...props
                                                        }) => {
    const [user] = useAuth();

    return (
        <Route
            {...props}
            render={({ location }) =>
                !!user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default App;
