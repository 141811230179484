import styled from "styled-components";
import { NEUTRAL_300 } from "../constants/colours";

export const AssessmentCard = styled.div`
    margin-bottom: 32px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid ${NEUTRAL_300};
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 
  `;